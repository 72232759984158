import Resource from '../resource';
import request from '../../utils/request';

class ClassRoom extends Resource {
    constructor() {
        super('classRoom');
    }
list(payload) {
    return request({
      url: `/classes`,
      method: 'post',
      data: payload
    });
  }

  put(id , payload) {
    return request({
      url: `/classes${id ? `/${id}` : ''}`,
      method: 'put',
      data: payload
    });
  }

  remove(id) {
    return request({
      url: `/classes/${id}`,
      method: 'delete',
    });
  }

  get(id) {
    return request({
      url: `/classes/${id}`,
      method: 'get',
    });
  }
}



export {ClassRoom as default};
