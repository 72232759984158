import Resource from '../../resource'
import request from '../../../utils/request'

class School extends Resource {
  constructor() {
    super('schools')
  }

  export(payload){
    return request({
      url: `/schools/export`,
      method: 'post',
      responseType: "blob",
      data:payload,
    });
  }
}

export { School as default }
