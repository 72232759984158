export default [
  {
    path: "/attendance",
    name: "attendance",
    permission: "access_offering",
    component: () => import("@/views/attendance/index"),
    meta: {
      pageTitle: "Attendance",
      breadcrumb: [{ text: "Global.Attendance", active: true }],
    },
  },
];
